<template>
  <div class="email_page">
    <div class="wrapper">
      <h2>
        Залиште ваш e-mail.<br />Ви&nbsp;отримаєте результати тесту на&nbsp;вашу
        електронну адресу як&nbsp;тільки завершите тестування.
      </h2>
      <div class="email_field">
        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="Email"
          outlined
          required
        ></v-text-field>
        <router-link to="/tasks" :disabled="disabledBt">
          <v-btn
            elevation="2"
            class="main_button"
            color="#57b894"
            :disabled="!isComplete"
            @click="validate"
          >
            Почати!
          </v-btn>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    email: "",
    emailRules: [
      (v) => !!v || "E-mail обовязковий",
      (v) => /.+@.+\..+/.test(v) || "E-mail не вірно введенний",
    ],
  }),
  methods: {
    validate() {
      this.$store.set("user/email", this.email);
    },
  },
  computed: {
    isComplete() {
      if (
        this.email.length > 3 &&
        !document
          .querySelector(".email_field")
          .getElementsByClassName("error--text")[0]
      ) {
        return true;
      }

      return false;
    },
    disabledBt() {
      return false;
    },
  },
};
</script>

<style lang="scss">
.email_page {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  h2 {
    font-size: 24px;
    margin-bottom: 40px;
  }

  a {
    text-decoration: none;
  }

  .email_field {
    max-width: 250px;
    width: 100%;
    margin: 0 auto;

    .main_button {
      margin-top: 0px !important;
    }
  }
}
</style>